const Layout = ({ children }) => {
  return (
    <div style={{ backgroundColor: '#FAF3E0' }}>
      <div className='mx-auto max-w-[1100px] xl:max-w-[1280px] 2xl:max-w-[80%]'>
        <div className='bg-primary-gray'>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
