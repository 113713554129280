import { useEffect, useState } from 'react';
import CategoriesList from '@/components/CategoriesList/CategoriesList.jsx';

const shadowStyle = {
  left: 0,
  bottom: 0,
  position: 'absolute',
  opacity: 1,
  width: '100%',
  paddingTop: '160px',
  boxShadow: 'inset 0 - 140px 55px -61px #faf9f5',
};

const Header = ({ background, darkBg = true, smallBackground = null, filters = null, childrenOnly = false, children }) => {
  const [maxScroll, setMaxScroll] = useState(900);

  useEffect(() => {
    const updateMaxScroll = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setMaxScroll(2400); // Mobile
      } else if (width < 1024) {
        setMaxScroll(1900); // Tablet
      } else if (width < 1280) {
        setMaxScroll(1600); // Desktop
      } else {
        setMaxScroll(900); // Large
      }
    };

    updateMaxScroll();
    window.addEventListener('resize', updateMaxScroll);
    return () => {
      window.removeEventListener('resize', updateMaxScroll);
    };
  }, [maxScroll]);

  const bg = window.innerWidth < 768 && smallBackground ? smallBackground : background;

  return (
    <>
      <div
        className='relative flex flex-col overflow-hidden bg-cover bg-scroll bg-center bg-no-repeat'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className={`z-5 flex h-full w-full justify-center ${darkBg ? 'bg-black bg-opacity-70' : ''} pb-12 lg:flex-col break11:pb-32`}>
          <div className='my-2 sm:my-10'>{children}</div>
          {!childrenOnly ? (
            <div className='mb-10 hidden lg:block'>{filters ? <>{filters}</> : <CategoriesList textColor={'#fff'} />}</div>
          ) : null}
        </div>
        <div className='opacity-1 bottom-0 left-0 z-5 hidden w-full shadow-inner-xl lg:pt-80 break11:block' style={shadowStyle}></div>
      </div>
      <div className='mb-4 block break11:mt-4 break11:hidden'>
        {filters ? (
          <>{filters}</>
        ) : (
          <CategoriesList
            imageClassName='border-4 border-primary-yellow h-32 w-32 xl:h-48 xl:w-48'
            textClassName='w-32 text-[10px] xl:w-48 xl:text-sm'
            gapClassName='gap-[2px]'
            textColor={'#000'}
            gridCols={3}
          />
        )}
      </div>
    </>
  );
};

export default Header;
